import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Applied Hydrology MSC`}</h1>
    <h2>{`Hydrology Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PMWE0RzVRNmdXTHc"
      }}>{`Download: Engineering Hydrology by K Subramanya 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PSHlBM2IyYzBJWFU"
      }}>{`Download: Solution Manual Engineering Hydrology by K Subramanya 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVzAxMEVmUlFSTUE"
      }}>{`Download: Surface water hydrology by N.M. Awan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PQ0l6cWdWcHl0UWc"
      }}>{`Download: Applied hydrology by Ven Te Chow, David R. Maidment and Larry W. Mays`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZy1BdlBGaVJGdTg"
      }}>{`Download: Concise Hydrology by Dawei Han`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PbFhkaVZnREtXWGM"
      }}>{`Download: Engineering Hydrology by Yilma Seleshi`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PRWNkcHpBSWtHT0k"
      }}>{`Download: Engineering Hydrology by Dr. Aqeel Al Adili`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1-xgvWnezHqANa0UhzPhbKcgBddg4stAg"
      }}>{`Download: Essentials of Meteorology An invitation to atmosphere by C. Donald Ahrens 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1OKZQ7DiV1ejP1kOql6PpqSmz3hPTAYGW"
      }}>{`Download: Handbook of hydrology by David R. Maidment `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVk1fckpuS0JzbU0"
      }}>{`Download: Hydrology for engineers by Ray K. Linsley, Max A. Kohler and Joseph L.H. Paulhus `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1b2eiRBeSVUmbgvplMWGv_-XSOd1Var8d"
      }}>{`Download: Hydrology Principles, Analysis and Design by H.M. Raghunath`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PczJ0cWJtb2s1MDQ"
      }}>{`Download: Introduction to hydrology by Warren Viesmann and Gary L. Lewis `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1GytzT7o22gH9kcxCotubxeGoYQbAPtjo"
      }}>{`Download: Manual on estimation of probable maximum precipitation by WMO`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1iuToRop8cHjdscLqEi79sZO31T2hmH6Q"
      }}>{`Download: Technical guide River and stream systems: Flooding Hazard limit Ontario`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1RrWo4oEG47lI_M8cljvOhMfQp4l3hz89"
      }}>{`Download: Flood hydrology manual A water resources technical publication by USBR `}</a></p>
    <h2>{`Applied Hydrology Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/1_introduction-to-applied-hydrology.pdf"
      }}>{`1_Introduction to applied hydrology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/2_Global-energy-and-water-cycle.pdf"
      }}>{`2_Global energy and water cycle`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/3_Precipitation.pdf"
      }}>{`3_Precipitation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/4_Evapotranspiration.pdf"
      }}>{`4_Evapotranspiration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/5_Canopy-interception-depression-storage-and-infiltration.pdf"
      }}>{`5_Canopy interception, depression storage and infiltration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/6_Groundwater-flow.pdf"
      }}>{`6_Groundwater flow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/7_Hydrological-modelling.pdf"
      }}>{`7_Hydrological modelling`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/7b_Numerical-solution-of-Saint-Venants-equations.pdf"
      }}>{`7b Numerical solution of Saint Venant’s equations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/8_floods.pdf"
      }}>{`8_Floods`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/global-energy-and-water-cycles.pdf"
      }}>{`Global energy and water cycles`}</a></p>
    <h2>{`Applied hydrology notes`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/1.-Principles-of-water-resources-engineering-Surface-and-ground-water-resources.pdf"
      }}>{`1. Principles of water resources engineering (Surface and ground water resources)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/2.-Principles-of-water-resources-engineering-Concepts-of-planning-water-resources-development.pdf"
      }}>{`2. Principles of water resources engineering (Concepts of planning water resources development)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/3.-Principles-of-water-resources-engineering-National-policy-for-water-resources-development.pdf"
      }}>{`3. Principles of water resources engineering (National policy for water resources development)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/4.-Principles-of-water-resources-engineering-Planning-and-assessment-of-data-for-project-formulation.pdf"
      }}>{`4. Principles of water resources engineering (Planning and assessment of data for project formulation)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/5.-The-science-of-surface-and-ground-water-Precipitation-and-evapotranspiration.pdf"
      }}>{`5. The science of surface and ground water (Precipitation and evapotranspiration)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/6.-The-science-of-surface-and-ground-water-Runoff-and-infiltration.pdf"
      }}>{`6. The science of surface and ground water (Runoff and infiltration)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/7.-The-science-of-surface-and-ground-water-Rainfall-runoff-relationships.pdf"
      }}>{`7. The science of surface and ground water (Rainfall runoff relationships)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/8.-The-science-of-surface-and-ground-water-Design-flood-estimation.pdf"
      }}>{`8. The science of surface and ground water (Design flood estimation)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/9.-The-science-of-surface-and-ground-water-Subsurface-movement-of-water.pdf"
      }}>{`9. The science of surface and ground water (Subsurface movement of water)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/10.-The-science-of-surface-and-ground-water-Principles-of-ground-water-flow.pdf"
      }}>{`10. The science of surface and ground water (Principles of ground water flow)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/11.-The-science-of-surface-and-ground-water-Well-hydraulics.pdf"
      }}>{`11. The science of surface and ground water (Well hydraulics)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/12.-The-science-of-surface-and-ground-water-Flow-dynamics-in-open-channels-and-rivers.pdf"
      }}>{`12. The science of surface and ground water (Flow dynamics in open channels and rivers)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/13.-The-science-of-surface-and-ground-water-Geomorphology-of-rivers.pdf"
      }}>{`13. The science of surface and ground water (Geomorphology of rivers)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/14.-The-science-of-surface-and-ground-water-Sediment-dynamics-in-alluvial-rivers-and-channels.pdf"
      }}>{`14. The science of surface and ground water (Sediment dynamics in alluvial rivers and channels)`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      